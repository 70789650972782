import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/human-capital-development-usecase.scss'
import ArrowRight from '../images/icons/arrow-right.svg'
import LouvreImg from '../images/human-capital-usecase/bg-louvre.png'
import BookImg from '../images/human-capital-usecase/bg-book.png'
import LinkImg from '../images/human-capital-usecase/bg-link.png'
import LockImg from '../images/human-capital-usecase/lock-img.png'
import { Link } from 'gatsby'

const HumanCapitalDevelopmentUsecase = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="human-capital-usecase py-5">
                <div className="hero-section">
                    <div className="h-100 d-flex align-items-end">
                        <div className="container px-5">
                            <div className="row mb-5 pb-5 px-4">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #E41700' }}></div>
                                        </div>
                                        <div className="col-md-11">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '3rem' }}><span className="text-red">Human capital development</span></h1>

                                            <p className="text-white mb-0" style={{ fontSize: '1.5rem' }}>Build your workforce and equip them with skills to build your business.</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-1"></div>

                                <div className="col-md-4 my-auto">
                                    <p className="small off-white  mb-0" style={{ lineHeight: '22px' }}>From multinationals to small businesses; no matter the industry, market or geography; we will equip you with tools to build, sustain and expand your offerings to compete and dominate in the global market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5 pt-5">
                    <div className="my-md-5 py-md-5">
                        <h1 className="big-text text-center font-weight-bold mb-5 pb-5 mt-5 px-md-5 px-0">Here’s what we have <span className="text-red">done</span> for <span className="text-red">businesses</span> just like yours…</h1>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="louvre-bg">
                                <img src={LouvreImg} alt="background" />
                            </div>

                            <p className="text-uppercase small mt-4 small inner-page-breadcrumb"><span className="text-secondary mr-2">Managed services</span>Telecom</p>

                            <Link to="/managed-services-the-louvre"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>Connecting our clients to competent IT geniuses to perform specialised services.</h4></Link>

                            <Link to="/managed-services-the-louvre"><p className="small cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                        </div>

                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={BookImg} alt="background" />
                            </div>

                            <p className="text-uppercase small mt-4 small inner-page-breadcrumb"><span className="text-secondary mr-2">Capacity building</span>Insurance</p>

                            <Link to="/capacity-building-unilever"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>Training a workforce in all round organisational operations.</h4></Link>

                            <Link to="/capacity-building-unilever"><p className="small cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                        </div>
                    </div>


                    {/* <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={LinkImg} alt="background" />
                            </div>

                            <p className="text-uppercase small mt-4 small inner-page-breadcrumb"><span className="text-secondary mr-2">Managed services</span>Delware Tech</p>

                            <h5 className="cursor black-link-text">Reinforcing network security for a global conglomerate</h5>

                            <p className="small cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                        </div>

                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={LockImg} alt="background" />
                            </div>

                            <p className="text-uppercase small mt-4 small inner-page-breadcrumb"><span className="text-secondary mr-2">Managed services</span>Proctor law</p>

                            <h5 className="cursor black-link-text">helping a law firm protect their intellectual property</h5>

                            <p className="small cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                        </div>

                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={LinkImg} alt="background" />
                            </div>

                            <p className="text-uppercase small mt-4 small inner-page-breadcrumb"><span className="text-secondary mr-2">Capacity building</span>Delware Tech</p>

                            <h5 className="cursor black-link-text">Reinforcing network security for a global conglomerate.</h5>

                            <p className="small cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                        </div>
                    </div> */}
                </div>
            </div>

        </Layout>
    )
}

export default HumanCapitalDevelopmentUsecase
